/* For now using My Space 2 color palette */
@font-face {
  font-family: 'indie-flower';
  src: url('../public/Fonts/Indie_Flower/IndieFlower-Regular.ttf')
}

@font-face {
  font-family: 'kalam-regular';
  src: url('../public/Fonts/Kalam/Kalam-Regular.ttf')
}

@font-face {
  font-family: 'kalam-light';
  src: url('../public/Fonts/Kalam/Kalam-Light.ttf')
}



body {
  margin-block: 0;
  margin-inline: auto;
  max-width: 1920px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button,
select,
a,
i,
ul,
section {
  -webkit-tap-highlight-color: transparent;
}

select:focus {
  outline: none;
}

button {
  cursor: pointer;
}

input {
  border-radius: 3px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --darkGreen: #18946f;
  --green: rgb(79, 224, 181);
  --lightGreen: #BAFDE9;
  --red: #942607;
  --lightPink: #DEA697;
}


.header {
  font-size: 1.825rem;
}


#home-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  grid-template-rows: repeat(4, auto);
  padding-block-start: 1.5rem;
  padding-inline: 5%;
  row-gap: 1.5rem;
}

.down-arrow-button {
  display: block;
  margin-inline-start: auto;
  margin-block-end: 0.5rem;
  color: var(--darkGreen);
  background-color: white;
  border: none;
  cursor: pointer;
}

#signing-page-wrapper {
  height: 100vh;
  display: flex;
  background-color: var(--lightGreen);
}

#signing-page-container {
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 17rem;
  height: 19rem;
  flex-direction: column;
  margin: 6rem auto;
  padding-inline: 1rem;
  background-color: white;
  font-size: 1.2rem;
  border-radius: 5px;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.3);
}

.signing-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid black;
  margin-block-end: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.signing-section:hover {
  background-color: #f5f5f5;
}

.signing-section button {
  border: none;
  background-color: inherit;
  margin-inline-start: 1rem;
  font-size: 1.3rem;
}

.login-icon {
  height: 3rem;
  width: 3rem;
}


#icons-homepage-container {
  grid-row: 4;
  grid-column: 1/6;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-homepage {
  border: 1px solid var(--lightPink);
  border-radius: 3px;
  width: 6rem;
  height: 6rem;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.3);
  cursor: pointer;
}

.icon-homepage:hover {
  background-color: rgb(232, 228, 228);
  border: 1px solid var(--darkGreen);
}

.sticky-section {
  width: 100%;
  position: sticky;
  background-color: white;
  padding: 0.5rem;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.back-arrow {
  margin-inline-start: 0;
  color: var(--red);
  transition: transform 0.1s;
  cursor: pointer;
}

.back-arrow:hover {
  transform: scale(1.3);
}

.back-arrow:active {
  transform: scale(1.5);
}

/*NAVBAR */

.navbar-container {
  height: 3rem;
  border-block-end: 2px solid var(--red);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-icon {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: var(--darkGreen);
  color: white;
  font-size: 2rem;
  border: none;
  margin-inline-end: 5%;
  cursor: pointer;
}

.navbar-home-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin-inline-start: 5%;
  color: white;
  cursor: pointer;
  overflow: hidden;
}

.navbar-dropdown-menu {
  position: absolute;
  top: 3rem;
  right: 5%;
  display: flex;
  flex-direction: column;
  background-color: var(--darkGreen);
  border: 1px solid var(--darkGreen);
  color: white;
  border-radius: 5px;
  padding: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s;
  visibility: hidden;
  z-index: 3;
}

.navbar-dropdown-menu.active {
  opacity: 1;
  visibility: visible;

}

.navbar-dropdown-menu ul {
  list-style: none;
  padding-inline: 1rem;
  margin: 0;
}

.navbar-dropdown-menu li {
  margin-bottom: 1rem;
  border-bottom: 1px solid white;
  font-size: 1.2rem;
  cursor: pointer;
}

.navbar-dropdown-menu a {
  text-decoration: none;
  color: white;
}

.navbar-dropdown-menu li:hover {
  transform: scale(110%);
}

.signin-link {
  float: right;
  margin: 0.5rem;
}

/* ACCOUNT*/
#account-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}

#account-container dialog[open] {
  position: absolute;
  border: none;
  top: 8rem;
  opacity: 1;
  scale: 1;
  width: 80%;
  font-size: 1rem;
  box-shadow: 10px 10px 10px 5px rgb(0, 0, 0, 0.5);
  border-radius: 2px;
  z-index: 6;
  border: 1px solid var(--red);
}

#delete-account-dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  backdrop-filter: blur(2px)
}

.account-single-section {
  border-bottom: 1px solid var(--darkGreen);
}

.account-single-section h2:nth-child(1) {
  margin-block-end: 0;
}

.user-stats-number {
  font-weight: 700;
}

#delete-account-section {
  margin-block-start: 2rem;
  border: 1px solid var(--red);
  padding: 1rem;
}

.delete-account-button {
  color: var(--red);
  background-color: white;
  font-weight: 600;
  border: 1px solid var(--lightPink);
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
}

.delete-account-button:hover {
  color: white;
  background-color: #942607;
}

.confirm-delete-account-button {
  padding: 0.4rem 0.8rem;
  color: white;
  background-color: var(--red);
  border: none;
  border-radius: 3px;
  border: 1px solid var (--red)
}

.close-dialog-account {
  padding: 0.4rem 0.8rem;
  border: 1px solid grey;
}



/*HOMEPAGE TIME-LOCATION CONTAINER*/


.weather-homepage {
  grid-row: 1;
  grid-column: 3/6;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

}

.weather-homepage-temp-image-section {
  display: flex;
  align-items: center;
}

.weather-homepage p:nth-child(4),
.weather-homepage p:not(:nth-child(n+4)) {
  text-align: right;
  margin-block: 0;
}

.weather-homepage p:nth-child(2) {
  font-size: 3rem;
}

.weather-image {
  width: 5rem;
  height: 5rem;
}

.time-homepage {
  grid-column: 1/3;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.time-homepage p:nth-child(1) {
  margin-block: 0;
}

.quick-access-homepage {
  grid-column: 1/6;
  grid-row: 3;
  margin-inline: -5%;
  background-color: var(--lightPink);
  height: 6rem;
  display: flex;
  align-items: center;
}

.quick-access-element {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  background-color: white;
  border-radius: 50%;
  transition: all 100ms ease-in;
  margin-inline-start: 5%;
  overflow: hidden;
}

.quick-access-element:hover {
  background-color: rgb(232, 228, 228);
  cursor: pointer;
}

.quick-access-element.active {
  justify-content: flex-start;
  width: 80%;
  height: 16rem;
  border-radius: 3px;
  border: 1px solid var(--lightPink);
  transform: translate(-0%, -25%);
  background-color: white;
  padding-inline: 5%;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
}

.new-todo-form-homepage {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.new-todo-form-homepage.active {
  animation: fadeIn 200ms forwards;
}

.new-todo-input-homepage {
  font-size: 1rem;
  width: 19rem;
  border: none;
  border-bottom: 1px solid var(--lightPink);
  outline: none;
}

.new-todo-input-homepage::placeholder {
  color: rgb(178, 173, 173);
}

.add-todo-button {
  box-sizing: border-box;
  border-radius: 0 2px 2px 0;
  border: none;
  color: white;
  background-color: var(--darkGreen);
  padding: 0.4rem 0.5rem;
  height: 1.8rem;
  font-size: 1rem;
}

.todo-list-homepage-small {
  grid-column: 1/2;
  grid-row: 2/2;
  width: 7rem;
  height: 3rem;
  list-style: none;
  overflow: auto;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
  font-size: 1rem;
  margin-block-start: -1.1rem;
  transition: all 200ms ease-in;
  padding: 0;
  border-radius: 5px;
  cursor: pointer;
}

.todo-list-homepage-small li {
  border-bottom: 1px solid #d3d3d3;
  padding-block-end: 0.3rem;
  padding-inline-start: 0.3rem;
  font-size: 0.6rem;
  transition: all 200ms ease-in;
}

.todo-list-homepage-small.active {
  grid-column: 1/6;
  width: auto;
  height: auto;
  grid-row: 2;
  max-height: 30rem;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
  transform: translate(-0%, -25%);
  background-color: #f5f5f5;
  overflow: auto;
  transition: all 0.2s ease-out;
}

.todo-list-homepage-small.active li {
  border-bottom: 1px solid #d3d3d3;
  padding-block: 0.3rem;
  padding-inline: 1rem;
  font-size: 1rem;
  transition: all 0.2s ease-out;
}

.todo-pencil {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/*NOTES */
#notes-page-container {
  position: relative;
  background-image: url('../public/corkboard.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 5.1rem);
  padding-block-end: 2rem;
}

.notes-form-container {
  position: relative;
  background-image: url('../public/corkboard.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 3.1rem);
}

.notes-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  gap: 1.5rem;
  margin: 0 auto;
  max-width: 90%;
  justify-content: center;
}

.notes-search-section {
  margin-block-start: 1.5rem;
  grid-column: 1/-1
}

.notes-search-input {
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
  border-radius: 2px;
  outline: none;
  font-size: 1.1rem;
  width: 65%;
}

.notes-search-input:focus {
  border: 2px solid var(--darkGreen);
  outline: none;
  border-radius: 2px;
}

.single-note-container {
  height: 9rem;
  background-color: white;
  cursor: pointer;
  overflow: hidden;
  padding-inline-start: 0.3rem;
  padding-inline-end: 0.3rem;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
  border-radius: 2px;
}

.single-note-header-list {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.single-note-text-small {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.8rem;
  font-family: 'kalam-regular';
  margin-block: 0;
}

.single-note-header-list {
  margin-block: 0.5rem 0.3rem;
  font-family: 'kalam-regular';
}

.notes-pin {
  margin-inline-start: 45%;
  margin-block-start: 0.5rem;
}


.dialog-note-header-list {
  font-size: 1.7rem;
  margin-block: 2.3rem 0.3rem;
  font-family: 'kalam-regular';
}

.dialog-note-text-small {
  font-size: 1.1rem;
  font-family: 'kalam-regular';
  white-space: pre-line;
  margin-block: 0;
}

.notes-list-container dialog {
  opacity: 0;
  scale: 0;
  display: block;
  transition: all 0.25s;
}

.notes-list-container dialog[open] {
  position: fixed;
  border: none;
  top: 3.5rem;
  opacity: 1;
  scale: 1;
  width: 80%;
  overflow-y: auto;
  z-index: 9;
  padding: 1rem;
  font-size: 2rem;
  box-shadow: 10px 10px 10px 5px rgb(0, 0, 0, 0.7);
  max-height: 85%;
  border-radius: 2px;
}

.note-close-button {
  position: fixed;
  top: 0.5rem;
  z-index: 9;
  right: 0.6rem;
  padding: 0.2rem;
}

.note-to-be-removed {
  animation: move-left 300ms forwards;
}

@keyframes move-left {
  100% {
    transform: translate(-600%);
  }
}

.note-pencil {
  height: 2rem;
  margin-inline-start: 0;
  cursor: pointer;
}

.note-trash {
  height: 2rem;
  width: 2rem;
  margin-inline-end: 0;
  cursor: pointer;
}

.note-form {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 7rem;
  left: 2rem;
  right: 2rem;
  background-color: white;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
  border-radius: 3px;
}

.field {
  margin-block-end: 0.2rem;
}


.note-form input,
textarea {
  width: 100%;
  font-size: 1rem;
  border: 1px solid var(--lightPink);
  box-sizing: border-box;
}

.note-form input:focus,
.note-form textarea:focus {
  border: 2px solid var(--lightPink);
  outline: none;
}

.note-form textarea {
  resize: none;
  height: 17rem;
}

.loading-notification {
  margin-block-start: 2rem;
  text-align: center;
}

.notes-form-footer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  left: 2rem;
  right: 2rem;
  top: 33rem;
}

.note-form-button {
  width: 30%;
  font-size: 1rem;
  color: white;
  padding-block: 0.375rem;
  border-radius: 5px;
  border: none;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
}

.note-submit-button {
  background-color: var(--darkGreen);
}

.note-submit-button:active {
  transform: scale(110%);
  color: var(--darkGreen);
  background-color: white
}

.note-cancel-button {
  background-color: var(--red);
}

.note-cancel-button:active {
  transform: scale(110%);
  color: var(--red);
  background-color: white;
}

.delete-button {
  background-color: var(--red);
  color: white;
  border: none;
  padding-block: 0.375rem;
  padding-inline: 0.625rem;
  border-radius: 5px;
  font-size: 1rem;
}

.delete-button:active {
  transform: scale(110%);
  border: 2px solid var(--red);
  color: var(--red);
  background-color: white;
}

#edit-note-container {
  background-image: url('../public/corkboard.jpg');
  min-height: 100vh;
}

.note-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 1rem;
}

.edit-link {
  display: flex;
  align-items: center;
  margin-inline-start: 0;
  text-decoration: none;
  color: black;
}

.notes-error-msg-container {
  font-size: 1.1rem;
  text-align: center;
  position: fixed;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
  background-color: white;
  top: 4rem;
  width: 16rem;
  border-radius: 3px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  backdrop-filter: blur(2px);
}

/* CALENDAR */


#calendar-container {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.fc .fc-toolbar-title {
  margin: 0;
  padding-inline: 0;
  font-size: 1.2rem;
}

.fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
  margin-left: 0.65em;
  background-color: var(--darkGreen)
}

.fc-direction-ltr .fc-toolbar {
  padding: 0.2rem;
}

.fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {
  background-color: var(--darkGreen);
}

.fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
  background-color: var(--darkGreen);
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background-color: var(--lightPink);
  border: 2px solid var(--lightPink);
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  color: white;
}

dialog[open] {
  top: -20%;
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  animation: changing-opacity-open 0.3s forwards;
}

.calendar-close-dialog-button {
  padding: 0.4rem 0.8rem;
}

.calendar-add-event-form p {
  display: flex;
  align-items: center;
}

.calendar-add-event-form input,
.calendar-add-event-form button {
  box-sizing: border-box;
  padding: 0.4rem 0.5rem;
  height: 1.8rem;
}

.calendar-add-event-form input {
  width: 12rem;
  border-radius: 2px 0 0 2px;
  border: 1px solid var(--lightPink);
  outline: none;
}

.calendar-add-event-form-button {
  box-sizing: border-box;
  border-radius: 0 2px 2px 0;
  border: none;
  color: white;
  background-color: var(--darkGreen);
}

.calendar-add-event-form-button:hover {
  transform: scale(1.05);
}

.dialog-delete-event-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem;
}

.dialog-delete-event-footer button:nth-child(2) {
  padding: 0.4rem 0.8rem;
  color: white;
  background-color: var(--red);
  border: none;
  border-radius: 3px;
}

dialog::backdrop {
  background: rgba(9, 14, 13, 0.7);
}

@keyframes changing-opacity-open {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/* IDEAS */

#quotes-container {
  margin: 1rem auto;
  width: 90%;
}

.quotes-header {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--red)
}

.quotes-header:nth-child(1) {
  color: var(--red);
  font-size: 1.4rem;
}

.single-quote {
  margin-block-end: 1.375rem;
  padding-block-end: 1.375rem;
  border-block-end: 1px solid var(--lightPink);
}

.single-quote-button {
  width: 100%;
  font-size: 1rem;
  color: white;
  background-color: var(--darkGreen);
  padding-block: 0.375rem;
  margin-block-start: 0.375rem;
  padding-inline: 0.;
  border-radius: 5px;
  border: none;
}

.single-quote-button:active {
  transform: scale(110%);
  color: var(--darkGreen);
  background-color: white;
  border: 2px solid var(--darkGreen);
}

.generate-quotes-button {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  margin-block-end: 1.375rem;
  border-radius: 5px;
  border: none;
  background-color: var(--darkGreen);
  color: white;
  font-size: 1.375rem;
}

.quote-homepage-container {
  margin: 0.6rem auto;
  grid-row: 2;
  grid-column: 1/6;
}

.quote-homepage-visible {
  color: var(--red);
  font-style: italic;
  font-weight: 600;
  text-indent: 10px;
  transition: 0.1s linear;
  margin: 1.5rem auto 0 auto;
}


.quote-homepage-hidden {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 0.1rem;
  color: white;
  font-style: italic;
  font-weight: 600;
  text-indent: 10px;
}

.quote-text {
  line-height: 1.5rem;
}


/*GAMES */

#icons-games-container {
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.game-link-button {
  text-align: center;
  display: block;
  margin: 2rem auto;
  width: 90%;
  height: 4rem;
  text-align: center;
  background-color: var(--darkGreen);
  color: white;
  font-size: 3rem;
  text-decoration: none;
}

#rps-game-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#games-header {
  text-align: center;
  margin-block-start: 1rem;
}

#games-header h1 {
  font-size: 2rem;
}

/*RPS GAME*/
.rps-container {
  margin-block-start: 3rem;
  display: flex;
  justify-content: center;
  gap: 6rem;
  padding-block-end: 2rem;
  border-bottom: 1px solid var(--lightPink);
}

.rps-ratio {
  margin-top: 1rem;
  color: var(--darkGreen);
  padding: 1rem 0.8rem;
  border: 1px solid var(--lightPink);
}

.start-game-button {
  width: 80%;
  font-size: 1.2rem;
  padding: 0.325rem;
  margin-block-start: 3rem;
  background-color: var(--darkGreen);
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgb(0, 0, 0, 0.4);
}

.start-game-button:hover {
  transform: scale(1.01, 1.02)
}

.start-game-button:active {
  transform: scale(1.06, 1.08)
}

.players {
  width: 80px;
  height: 80px;
  background-color: var(--red);
  text-align: center;
  color: white;
  font-size: 3rem;
  font-weight: 700;
  border-radius: 150px;
  line-height: 4.5rem;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
}

.rps-choice-section-container {
  display: flex;
  align-content: space-between;
  margin-top: 2rem;
}

.rps-element {
  width: 3.5rem;
  height: 3.5rem;
  margin: 2rem;
  cursor: pointer;
}

.rps-element:hover {
  transform: scale(110%);
}

.choosen-element {
  margin: 0 2rem;
  width: 5rem;
  height: 5rem;
}

.choice-section:hover {
  transform: scale(1.2);
}

.choice-section:active {
  transform: scale(1.4);
}

.result-article {
  margin-block: 3rem;
  display: flex;
  gap: 3rem;
  font-size: 3rem;
}

.game-result-header {
  margin: 0;
  font-size: 2rem;
  color: var(--red);
}

/*ShipsGame*/

#ships-game-container {
  display: flex;
  justify-content: center;
}

.ships-header {
  grid-row: 1;
  grid-column: 1/3;
}

#ships-battle-area {
  display: grid;
  gap: 0.2rem;
  height: 38rem;
  grid-template-rows: 1.8rem 1.8rem auto;
  grid-template-columns: 1rem 400px auto;
}

.alphabet-container {
  display: flex;
  grid-row: 1;
  grid-column: 2/3;
}

.alphabet-ships-square {
  text-align: center;
  width: 25px;
  height: 25px;
  border: 1px solid grey;
}

#numbers-container {
  grid-row: 2/3;
  grid-column: 1
}

.numbers-square {
  text-align: center;
  width: 25px;
  height: 25px;
  border: 1px solid grey;
}

.square {
  width: 25px;
  height: 25px;
  border: 1px solid blue;
  cursor: pointer;
  background-color: inherit;
}

.square-hit {
  width: 25px;
  height: 25px;
  border: 1px solid blue;
  cursor: pointer;
  background-color: rgb(161, 185, 209);
}

.ship-hit {
  width: 25px;
  height: 25px;
  border: 1px solid blue;
  cursor: pointer;
  background-color: var(--red);
}

#battle-area {
  width: 375px;
  display: flex;
  flex-wrap: wrap;
  grid-row: 2/3;
  grid-column: 2/3;
  text-align: center;
  background-color: rgb(196, 229, 240);
}



#ships-header-container {
  display: flex;
  flex-direction: column;
  margin-block-end: 1rem;
}

.start-ships-game-button {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}

#user-battle-field {
  width: 375px;
  display: flex;
  flex-wrap: wrap;
}








.grid-element-border {
  border: 1px solid black;
}

.grid-element1 {
  grid-column: 1;
  grid-row: 1;
  justify-content: right;
}

.grid-element2 {
  grid-column: 2;
  grid-row: 1;
}

.grid-element3 {
  grid-column: 3;
  grid-row: 1;
}

.grid-element4 {
  grid-column: 4;
  grid-row: 1;
}

/* WEATHER */
.location-search-input {
  border-radius: 3px;
  border: 1px solid rgb(193, 190, 190);
}

.location-search-input::placeholder {
  color: rgb(165, 162, 162);
}

.no-locations-info {
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
  width: 90%;
  color: var(--red)
}

#weather-page-container {
  margin: 1rem auto;
  width: 90%;
  display: flex;
  flex-direction: column;
}

#places-autocomplete-section {
  margin-block-start: 1rem;
  width: 100%;
}

#places-autocomplete-section input {
  width: 80%;
  height: 1.8rem;
  font-size: 1rem;
  padding-inline-start: 0.4rem;
  box-sizing: border-box;
}

#places-autocomplete-section input:focus {
  border: 2px solid var(--lightPink) !important;
  outline: none;

}

.autocomplete-dropdown-container {
  position: absolute;
  background-color: white;
  border: 1px solid var(--lightPink);
  max-width: 60%;
}

.suggestion-item--active {
  background-color: var(--darkGreen);
  color: white;
  cursor: pointer;
  padding: 0.3rem;
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);

}

.single-location {
  display: flex;
  flex-wrap: wrap;
  margin-block-start: 1rem;
  border: 1px solid rgb(133, 130, 130);
  height: 3.4rem;
  transition: 0.15s linear;
  overflow: hidden;
  border-radius: 5px;
  color: white;
}

.detail-location {
  display: flex;
  flex-wrap: wrap;
  margin-block-start: 1rem;
  border: 1px solid var(--lightPink);
  height: 29rem;
  transition: 0.4s ease-in-out;
  overflow: hidden;
  border-radius: 5px;
}

.weather-detail-location-header {
  box-sizing: border-box;
  display: flex;
  align-items: baseline;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding-inline-start: 0.4rem;
  color: black;
}

.detail-location-data {
  padding-inline-start: 0.4rem;
}

.down-arrow-button-weather {
  margin-inline-start: auto;
  margin-block-end: 0.5rem;
  color: var(--darkGreen);
  background-color: white;
  border: none;
  cursor: pointer;
}

.searched-locations {
  width: 95%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0.5rem;
  border: 1px solid rgb(222, 222, 222);
  margin-block: 1.5rem;
}

.temperature-section {
  margin-inline-start: 0;
  font-size: 1rem;
}

.add-location-button {
  width: 35px;
  height: 35px;
  background-color: var(--darkGreen);
  border-radius: 50%;
  border: none;
  color: white;
}

.add-location-to-homepage {
  width: 100%;
  font-size: 1rem;
  color: white;
  background-color: var(--darkGreen);
  margin-block-start: 1rem;
  padding-block: 0.375rem;
  border-radius: 0 0 3px 3px;
  border: none;
}

.add-location-to-homepage:active {
  background-color: white;
  color: var(--darkGreen)
}

.modal-visible {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
}

.modal-info {
  margin-top: 6rem;
  color: white;
  width: 90%;
}





/*TIC TAC TOE*/

.ttt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
  text-align: center;
  background-color: antiquewhite;
  min-height: 100vh;
}

.ttt-game-area-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 380px;
  height: 380px;
  background-color: #9e8d73;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  grid-gap: 2px;
}

.ttt-select-container {
  padding: 0.4rem 1rem;
  font-size: 1rem;
  background-color: var(--darkGreen);
  color: white;
  border: none;
  border-radius: 3px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
}


.sign-lvl-choose-section {
  display: flex;
  justify-content: space-between;
  width: 380px;
  margin-block: 2rem;
}

.line-horizontal-top::after,
.line-horizontal-middle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 3px solid black;
}

.reset-ttt {
  width: 24rem;
  font-size: 1.2rem;
  padding: 0.325rem;
  margin-block-start: 3rem;
  background-color: var(--darkGreen);
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
}

.reset-ttt:hover {
  transform: scale(1.01, 1.02)
}

.reset-ttt:active {
  transform: scale(1.03, 1.06)
}

.ttt-square {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5deb3;
  cursor: pointer;
}

.filled {
  pointer-events: none;
}

.line-horizontal-top::after,
.line-horizontal-middle::after,
.line-horizontal-bottom::after {
  content: "";
  position: absolute;
  width: 96%;
  border-top: 4px solid var(--red);
}

.line-horizontal-top::after {
  left: 2%;
  top: 17%;
}

.line-horizontal-middle::after {
  left: 2%;
  top: 50%;
}

.line-horizontal-bottom::after {
  left: 2%;
  top: 83%;
}

.line-vertical-left::after,
.line-vertical-middle::after,
.line-vertical-right::after {
  content: "";
  position: absolute;
  top: 2%;
  height: 96%;
  border-left: 4px solid var(--red);
}

.line-vertical-left::after {
  left: 15%;
}

.line-vertical-middle::after {
  left: 50%;
}

.line-vertical-right::after {
  left: 83%;
}

.line-diagonal-left::after,
.line-diagonal-right::after {
  content: "";
  position: absolute;
  width: 130%;
  border-top: 4px solid var(--red);
}

.line-diagonal-left::after {
  left: -15%;
  top: 50%;
  transform: rotate(45deg);
}

.line-diagonal-right::after {
  right: -15%;
  top: 49%;
  transform: rotate(-45deg);
}


.circle-svg {
  width: 100%;
  height: 100%;
}

.circle {
  fill: none;
  stroke: #f5deb3;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: drawCircle 0.5s linear forwards;
}

@keyframes drawCircle {
  to {
    stroke-dashoffset: 0;
  }
}

.x-svg {
  height: 100%;
  width: 100%;
}

.x-line1,
.x-line2 {
  stroke: #f5deb3;
  stroke-width: 3;
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
}

.x-line1 {
  animation: drawX 0.3s linear forwards;
}

.x-line2 {
  animation: drawX 0.3s linear forwards 0.4s;
}

@keyframes drawX {
  to {
    stroke-dashoffset: 0;
  }
}


.ttt-winner-banner {
  position: absolute;
  top: 20%;
  font-size: 2rem;
  color: white;
  background-color: #9e8d73;
  padding: 0.7rem 2.2rem;
  border-radius: 3px;
}




/*NotFound */

#not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-image {
  margin-block: 2rem;
  height: 8rem;
  width: 8rem;
}


/*AppGuide*/
#app-guide-container {
  width: 90%;
  margin: 0 auto;
}

.app-guide-section-container {
  border-bottom: 1px solid var(--darkGreen);
  padding-block-end: 2rem;
}

.icon-app-guide-quick-access {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  float: left;
  padding: 0.5rem;
  margin-inline-end: 0.8rem;
  border-radius: 50%;
  border: 1px solid black;
}

.icon-app-guide-quick-access img {
  width: 2.5rem;
  height: 2.5rem;
}

.app-guide-section-container p {
  line-height: 1.5;
  word-spacing: 3px;
}

.icon-app-guide {
  width: 5rem;
  height: 5rem;
  float: left;
  margin: 0 0.8rem 0.8rem 0;
}

.icon-within-text {
  height: 1rem;
  padding-inline: 0.3rem;
}




/* ------------------*/
.date-paragraph {
  font-style: italic;
  font-size: 0.675rem;
  padding-block: 0.375rem;
  margin-inline-start: 0;
  margin-block: 1rem;
  font-weight: 500;
}


.notes-add-button {
  box-shadow: 3px 4px 4px 4px rgb(0, 0, 0, 0.4);
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  color: white;
  cursor: pointer;
}

.notes-add-button:active {
  transform: scale(110%);
}

.label-standard {
  font-weight: bold;
}

#privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding-inline: 1rem;
}

#privacy-policy-container li {
  margin-block-end: 1rem;
}

.privacy-policy-link {
  font-size: 0.8rem;
  font-weight: 600;
}

.hidden {
  display: none;
}


@media screen and (min-width: 768px) and (max-width: 3440px) {

  /*NOTES DESKTOP */

  .notes-search-section {
    width: 45%;
  }

  .notes-list-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    gap: 2rem;
    margin: 0 auto;
    max-width: 90%;
  }

  .single-note-container {
    height: 18rem;
  }

  .single-note-header-list {
    white-space: wrap;
    overflow: hidden;
  }

  .single-note-text-small {
    font-family: 'kalam-regular';
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }

  .notes-list-container dialog[open] {
    width: 60%;
  }

  .note-form,
  .notes-form-footer {
    width: 60%;
    margin: 0 auto;
  }

  /*WEATHER DESKTOP */
  #places-autocomplete-section input {
    width: 40%;
    box-shadow: 2px 3px 3px 3px rgb(0, 0, 0, 0.2);
  }

  #locations-fromDB-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    gap: 2rem;
    margin: 0 auto;
    max-width: 100%;
  }

  .detail-location {
    box-shadow: 2px 3px 3px 3px rgb(0, 0, 0, 0.3);
  }

  .down-arrow-button-weather {
    display: none;
  }

  .searched-locations {
    width: 30%;
    border-radius: 3px;
  }

  /*QUOTES*/
  #quotes-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem
  }

  .quotes-header {
    grid-column: 1/4;
  }

  .single-quote {
    border-bottom: none;
  }

  /* RPS */
  .rps-container {
    width: 40%;
  }

  .start-game-button {
    width: 19rem;
  }

  /* TODOS */

  .quick-access-element.active {
    width: 21rem;
  }

  .todo-list-homepage-small.active {
    width: 24rem;
  }

  /* HOME */
  .navbar-container {
    width: 100%;
    margin-inline: auto;
  }

  #home-container {
    row-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    grid-template-rows: repeat(3, auto);
  }

  .time-homepage {
    grid-column: 1/2;
    grid-row: 1;
  }

  .todo-list-homepage-small {
    grid-row: 1;
    grid-column: 2/3;
    margin-block-start: 2rem;
    height: auto;
    max-height: 8rem;
    width: 80%;
  }

  .todo-list-homepage-small.active {
    visibility: visible;
    grid-row: 1;
    grid-column: 2/5;
    width: 80%;
  }

  .todo-list-homepage-small li {
    padding-block: 0.4rem;
  }

  .weather-homepage {
    grid-column: 5/6
  }

  .quote-homepage-container {
    grid-row: 1;
    grid-column: 3/5;
  }

  .quote-homepage-quote {
    margin-inline: 1rem;
  }

  .quote-homepage-container button {
    display: none;
  }

  .quick-access-homepage {
    grid-row: 3;
    grid-column: 1/6;
  }

  .quick-access-element {
    margin-inline-start: 15%;
  }

  #icons-homepage-container {
    width: 100%;
    grid-row: 4;
    grid-column: 1/6;
  }

  .icon-homepage {
    width: 8rem;
    height: 8rem;
  }

  .single-note-container {
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
  }
}